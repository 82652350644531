import pick from 'lodash/pick';
import config from '../../config';
import { initiatePrivileged, updateBiddingData } from '../../util/api';
import { sendTimedAuctionEmail, sendTimedAuctionEmailToBidder, setStartDate, setTime } from '../../util/custom-api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { isPrivileged, TRANSITION_MAKE_TIMED_AUCTION_OFFER, TRANSITION_AUTO_DECLINE_BELOW_RESERVE_PRICE } from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertMoneyAmount } from '../../util/currency';
import { EMAIL } from '../../util/types';
import { isAfterDate } from '../../util/dates';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/TimedAuctionCheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_OFFER_REQUEST = 'app/TimedAuctionCheckoutPage/INITIATE_OFFER_REQUEST';
export const INITIATE_OFFER_SUCCESS = 'app/TimedAuctionCheckoutPage/INITIATE_OFFER_SUCCESS';
export const INITIATE_OFFER_ERROR = 'app/TimedAuctionCheckoutPage/INITIATE_OFFER_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const SAVE_SHIPPING_ADDRESS_REQUEST = 'app/ShippingAddressPage/SAVE_SHIPPING_ADDRESS_REQUEST';
export const SAVE_SHIPPING_ADDRESS_SUCCESS = 'app/ShippingAddressPage/SAVE_SHIPPING_ADDRESS_SUCCESS';
export const SAVE_SHIPPING_ADDRESS_ERROR = 'app/ShippingAddressPage/SAVE_SHIPPING_ADDRESS_ERROR';

export const UPDATE_BIDDING_DATA_REQUEST = 'app/ShippingAddressPage/UPDATE_BIDDING_DATA_REQUEST';
export const UPDATE_BIDDING_DATA_SUCCESS = 'app/ShippingAddressPage/UPDATE_BIDDING_DATA_SUCCESS';
export const UPDATE_BIDDING_DATA_ERROR = 'app/ShippingAddressPage/UPDATE_BIDDING_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  offer: null,
  shipping: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOfferError: null,
  saveShippingAddressError: null,
  saveShippingAddressInProgress: false,
  updateBiddingDataError: null,
  updateBiddingDataInProgress: false
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_OFFER_REQUEST:
      return { ...state, initiateOfferError: null };
    case INITIATE_OFFER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_OFFER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOfferError: payload };

    case SAVE_SHIPPING_ADDRESS_REQUEST:
      return {
        ...state,
        saveShippingAddressInProgress: true,
        saveShippingAddressError: null,
      };
    case SAVE_SHIPPING_ADDRESS_SUCCESS:
      return { ...state, saveShippingAddressInProgress: false };
    case SAVE_SHIPPING_ADDRESS_ERROR:
      return { ...state, saveShippingAddressInProgress: false, saveShippingAddressError: payload };
    case UPDATE_BIDDING_DATA_REQUEST:
      return {
        ...state,
        updateBiddingDataInProgress: true,
        updateBiddingDataError: null,
      };
    case UPDATE_BIDDING_DATA_SUCCESS:
      return { ...state, updateBiddingDataInProgress: false };
    case UPDATE_BIDDING_DATA_ERROR:
      return { ...state, updateBiddingDataInProgress: false, updateBiddingDataError: payload };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateTimedAuctionOfferRequest = () => ({ type: INITIATE_OFFER_REQUEST });

const initiateTimedAuctionOfferSuccess = order => ({
  type: INITIATE_OFFER_SUCCESS,
  payload: order,
});

const initiateTimedAuctionOfferError = e => ({
  type: INITIATE_OFFER_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const saveShippingAddressRequest = () => ({ type: SAVE_SHIPPING_ADDRESS_REQUEST });
export const saveShippingAddressSuccess = () => ({ type: SAVE_SHIPPING_ADDRESS_SUCCESS });
export const saveShippingAddressError = error => ({
  type: SAVE_SHIPPING_ADDRESS_ERROR,
  payload: error,
  error: true,
});

export const updateBiddingDataRequest = () => ({ type: UPDATE_BIDDING_DATA_REQUEST });
export const updateBiddingDataSuccess = () => ({ type: UPDATE_BIDDING_DATA_SUCCESS });
export const updateBiddingDataError = error => ({
  type: UPDATE_BIDDING_DATA_ERROR,
  payload: error,
  error: true,
});

/* ================ Thunks ================ */

export const initiateTimedAuctionOffer = (offerParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateTimedAuctionOfferRequest());

  const transition = TRANSITION_MAKE_TIMED_AUCTION_OFFER;

  const offerData = {
    offer: {
      amount: offerParams.offer.amount / 100, // For the notification email as we can't calculate in the template
      currency: offerParams.offer.currency
    },
    lineItems: offerParams.lineItems,
    isTimedAuction: offerParams.isTimedAuction,
    remainingTime: offerParams.remainingTime
  };

  const bodyParams = {
    processAlias: config.bookingProcessAlias,
    transition,
    params: offerParams,
  };
  const queryParams = {
    include: ['booking', 'provider', 'customer', 'listing'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    const txOffer = entities[0];
    dispatch(updateCurrentBiddingData({
      listingId: txOffer.listing.id,
      userId: txOffer.customer.id,
      offerPrice: offerParams.offer.amount,
      transactionId: txOffer.id.uuid,
      hasReservePriceAlreadyMet: offerParams.isReservePriceMet
    }));
    dispatch(initiateTimedAuctionOfferSuccess(txOffer));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return txOffer;
  };

  const handleError = e => {
    dispatch(initiateTimedAuctionOfferError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-txOffer-failed', {
      ...transactionIdMaybe,
      listingId: offerParams.listingId.uuid,
    });
    throw e;
  };

  return initiatePrivileged({ isSpeculative: false, bookingData: offerData, bodyParams, queryParams })
    .then(handleSuccess)
    .catch(handleError);
};

export const initiateOrderAutomaticDeclined = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateTimedAuctionOfferRequest());

  const transition = TRANSITION_AUTO_DECLINE_BELOW_RESERVE_PRICE;

  const offerData = {
    offer: {
      amount: convertMoneyAmount(orderParams.offer.amount), // For the notification email as we can't calculate in the template
      currency: orderParams.offer.currency
    },
    lineItems: orderParams.lineItems,
    isTimedAuction: orderParams.isTimedAuction,
    remainingTime: orderParams.remainingTime
  };

  const bodyParams = {
    processAlias: config.bookingProcessAlias,
    transition,
    params: orderParams,
  };
  const queryParams = {
    include: ['booking', 'provider', 'customer', 'listing'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(updateCurrentBiddingData({
      listingId: order.listing.id,
      userId: order.customer.id,
      offerPrice: orderParams.offer.amount,
      transactionId: order.id.uuid
    }));
    dispatch(initiateTimedAuctionOfferSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateTimedAuctionOfferError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
    });
    throw e;
  };

  return initiatePrivileged({ isSpeculative: false, bookingData: offerData, bodyParams, queryParams })
  .then(handleSuccess)
  .catch(handleError);
};



export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (offerParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const transition = TRANSITION_MAKE_TIMED_AUCTION_OFFER;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    offer: offerParams.offer,
    lineItems: offerParams.lineItems
  };

  const params = {
    ...offerParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = {
    processAlias: config.bookingProcessAlias,
    transition,
    params,
  };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    // const { listingId, bookingStart, bookingEnd } = params;
    const { listingId } = params;

    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

export const saveShippingAddress = params => (dispatch, getState, sdk) => {
  dispatch(saveShippingAddressRequest());

  return sdk.currentUser
    .updateProfile(
      {
        publicData: { shippingAddress: params }
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }

      const currentUser = entities[0];
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveShippingAddressSuccess());
    })
    .catch(e => {
      dispatch(saveShippingAddressError(storableError(e)));
      throw e;
    })
};

export const updateCurrentBiddingData = ({listingId, userId, offerPrice, transactionId, hasReservePriceAlreadyMet = false}) => (dispatch, getState, sdk) => {
  dispatch(updateBiddingDataRequest());
  return updateBiddingData({listingId, userId, offerPrice, transactionId})
    .then((response) => {
      dispatch(updateBiddingDataSuccess());
      const { currentListing, currentUser } = response.data.data;
      const { isTimedAuction = false, timedAuction = {}, highestOffer, currentHighestBidder } = currentListing.data.data.attributes?.publicData || {};
      const { endDate, isReservePriceMet, isAutomated, isTimeExtended, hasStartDate, startDate } = timedAuction;

      // Time extension ( below 20 mins )
      if (isTimedAuction && endDate && isTimeExtended) {
        setTime({ listingId: listingId.uuid, endDate });
      }

      if (isReservePriceMet) {
        sendTimedAuctionEmail({ listingId: listingId.uuid, transactionId, type: EMAIL.TIMED_AUCTION.NEW_BID });
        if (isReservePriceMet !== hasReservePriceAlreadyMet) {
          sendTimedAuctionEmail({ listingId: listingId.uuid, transactionId, type: EMAIL.TIMED_AUCTION.MEET_RESERVE_PRICE });
          if (isAutomated && !isTimeExtended) {
            setTime({ listingId: listingId.uuid, endDate });
            if (hasStartDate && startDate && isAfterDate(startDate)) {
              setStartDate({ listingId, startDate });
            }
          }
        }
        if (offerPrice === highestOffer && currentHighestBidder.transactionId !== transactionId) {
          sendTimedAuctionEmailToBidder({ transactionId, type: EMAIL.TIMED_AUCTION.SAME_BID });
        }
      }
      dispatch(addMarketplaceEntities(currentListing));
      dispatch(addMarketplaceEntities(currentUser));
    })
    .catch(e => {
      dispatch(updateBiddingDataError(storableError(e)));
      log.error(e, 'update-bidding-data-failed', {listingId, userId, offerPrice});
    })
};
