import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel, Button } from '../../../components';
import ActionBarMaybe from './ActionBarMaybe';
import MediaCarousel from '../../../components/MediaCarousel/MediaCarousel';

import css from '../ListingPage.module.css';

const SectionMedia = props => {
  const {
    title,
    indexMedia,
    listing,
    isOwnListing,
    editParams,
    handleViewMediaClick,
    mediaCarouselOpen,
    onMediaCarouselClose,
    onManageDisableScrolling,
    handleNavigateMediaClick,
    media
  } = props;

  const [indexMediaRefresh, setIndex] = useState(indexMedia);
  const mediaItem = media[indexMediaRefresh];

  // DidUpdate
  useEffect(() => {
    setIndex(indexMedia)
  },[indexMedia]);

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const changeIndexHandler = (index) => {
    setIndex(index)
  }

  const prevButton = <button className={css.prevBtn} disabled={indexMedia === 0} onClick={(e) => handleNavigateMediaClick(e, -1)}/>;
  const nextButton = <button className={css.nextBtn} disabled={indexMedia === media.length - 1} onClick={(e) => handleNavigateMediaClick(e, 1)}/>;

  return (
      <div className={css.imageContent}>
        <div className={css.sectionImages}>
          <div className={mediaItem?.type ? css.aspectWrapper : css.aspectWrapperVideo} onClick={handleViewMediaClick}>
          {mediaItem?.type
            ? (<ResponsiveImage
              rootClassName={css.rootForImage}
              alt={title}
              image={mediaItem}
              variants={[
                'scaled-small',
                'scaled-medium',
              ]}
            />)
            : (
              <img 
                className={css.rootForImage} 
                alt={title} 
                src={mediaItem.thumbnail} 
                srcSet={`${mediaItem.thumbnail} 400w, ${mediaItem.thumbnail} 800w`} 
              />
            )
          }
          {prevButton}
          {nextButton}
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        contentClassName={mediaItem.type ? null : css.videoModalContent}
        lightCloseButton
        isOpen={mediaCarouselOpen}
        onClose={onMediaCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <MediaCarousel changeIndexHandler={changeIndexHandler} indexMedia={indexMediaRefresh} media={media} mediaCarouselOpen={mediaCarouselOpen} />)
      </Modal>
    </div>
  );
};

export default SectionMedia;
