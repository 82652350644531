import React, { Component, createRef } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl, FormattedHTMLMessage } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes, YES } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  getThumbnailFromYoutubeUrl,
} from '../../util/urlHelpers';
import { formatMoney, createMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  IconPhone,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';

import {
  sendEnquiry,
  loadData,
  setInitialValues,
  fetchTransactionLineItems,
  updateCurrentWatchlist,
  fetchCollections,
} from './ListingPage.duck';
import { requestUpdateListing } from '../EditListingPage/EditListingPage.duck';
import { ensureCurrentUser } from '../../util/data';
import {
  SectionHeading,
  SectionDescriptionMaybe,
  SectionDetailsMaybe,
  SectionWatchList,
  SectionMessageSeller,
  SectionTrading,
  SectionAuthenticityMaybe,
  SectionServiceMaybe,
  SectionFeaturedRecordsMaybe,
  SectionMedia,
  SectionListMedia,
  SectionShippingOptions,
  SectionCollectionMaybe,
  SectionTimedAuction
} from './sections';
import { EnquiryForm } from '../../forms';
import { openAuthModal } from '../../ducks/Auth.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import moment from 'moment-timezone';
import SectionShare from './sections/SectionShare';
import EmailReminder from '../../components/ModalMissingInformation/EmailReminder';
import { sendVerificationEmail } from '../../ducks/user.duck';

import css from './ListingPage.module.css';

const { UUID, Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      mediaCarouselOpen: false,
      indexMedia: 0,
      videoOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      collection: [],
      emailVerificationReminderOpen: false,
    };
    this.collectionSectionRef = createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.handleSubmitOffer = this.handleSubmitOffer.bind(this);
    this.onClickToUpdateWatchlist = this.onClickToUpdateWatchlist.bind(this);
  }

  componentDidUpdate() {
    const { fetchCollectionsInProgress, history } = this.props;

    if (history?.location.search && !fetchCollectionsInProgress && this.collectionSectionRef.current) {
      this.collectionSectionRef.current.scrollIntoView();
    }
  }

  handleSubmitOffer(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const { isTimedAuction = false } = listing?.attributes.publicData;
    const { offer } = values;

    const initialValues = {
      listing,
      offer: offer,
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const checkoutPage = isTimedAuction ? 'TimedAuctionCheckoutPage' : 'CheckoutPage';

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName(checkoutPage, routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        checkoutPage,
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const initialValues = {
      listing,
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const {
      currentUser,
      history,
      callSetInitialValues,
      params,
      location,
      isAuthenticated,
      dispatchOpenAuthModal,
    } = this.props;

    if (!isAuthenticated) {
      dispatchOpenAuthModal();
      return;
    }

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  onClickToUpdateWatchlist(action) {
    const { params, currentUser, onUpdateWatchlist, isAuthenticated, dispatchOpenAuthModal } = this.props;
    if (!isAuthenticated) {
      dispatchOpenAuthModal();
      return;
    }
    const listingId = new UUID(params.id);
    const userId = currentUser.id;
    const isUserEmailVerified = !!userId && !currentUser.attributes.emailVerified;

    if (isUserEmailVerified) {
      this.setState({ emailVerificationReminderOpen: true });
      return;
    }

    onUpdateWatchlist(listingId, userId, action);
  }

  render() {
    const {
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      sendEnquiryInProgress,
      sendEnquiryError,
      updateWatchlistInProgress,
      updateWatchlistError,
      dispatchOpenAuthModal,
      collections = [],
      pagination,
      fetchCollectionsInProgress,
      fetchCollectionsError,
      onFetchCollections,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      history,
    } = this.props;
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };
    const user = currentListing.author;
    const userIsCurrentUser = user && user.type === 'currentUser';
    const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
    const isUserEmailVerified = !!currentUser?.id && currentUser?.attributes.emailVerified;
    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      price = null,
      title = '',
      publicData,
      privateData,
      metadata,
    } = currentListing.attributes;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found
      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar><TopbarContainer /></LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar><TopbarContainer /></LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewMediaClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        mediaCarouselOpen: true,
      });
    };
    const handleMediaThumbnailClick = (e, index) => {
      e.stopPropagation();
      this.setState({
        indexMedia: index,
      });
    };
    const handleNavigateMediaClick = (e, step) => {
      e.stopPropagation();
      // Step: Next: 1, Previous: -1
      const newIndex = this.state.indexMedia + step;
      this.setState({
        indexMedia: this.state.indexMedia + step,
      });
    }
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    // Display number of watchers
    const { numOfWatchers = 0, isSold = false } = metadata || {};
    const isWatched =
      currentUser && metadata && metadata.watcherIds && metadata.watcherIds[currentUser.id.uuid];

    const {
      category,
      itemDetails,
      micDetails,
      authenticity,
      service,
      shipping,
      pricing,
      numberOfOffers,
      highestOffer,
      featuredRecords,
      youtubeLinks,
      timedAuction,
      isTimedAuction = false,
      extendDescription = '',
      isPendingAutomatedTimedAuction,
      currentHighestBidder
    } = publicData;

    const { showEstimatePrice, minEstPrice = {}, maxEstPrice = {}, askingPrice } = pricing;
    const isEstimatePriceShown = !!(minEstPrice?.amount || maxEstPrice?.amount) && showEstimatePrice === YES;
    const formattedMinEstPrice = minEstPrice?.amount && priceData(createMoney(minEstPrice), intl);
    const formattedMaxEstPrice = maxEstPrice?.amount && priceData(createMoney(maxEstPrice), intl);
    const estimatePriceLabel = intl.formatMessage({
      id: 'ListingPage.estimatePriceLabel'
    });
    const formattedEstimatePrice = intl.formatMessage({
      id: 'ListingPage.formattedEstimatePrice'
    }, {
      minEstPrice: formattedMinEstPrice?.formattedPrice,
      maxEstPrice: formattedMaxEstPrice?.formattedPrice
    });
    const estimatePrice = isEstimatePriceShown ? { formattedEstimatePrice, estimatePriceLabel } : null;

    const displayDescription = extendDescription || description;

    const isCollection = category === 'collection';

    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;

    if (isCurrentlyClosed) {
      return <NamedRedirect name="LandingPage" />;
    };

    const handleBookingSubmit = values => {
      if (!isAuthenticated) {
        dispatchOpenAuthModal();
        return;
      }

      if (!isUserEmailVerified) {
        this.setState({ emailVerificationReminderOpen: true });
        return;
      }
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        if (values && values.button !== 'buyNow') {
          this.handleSubmitOffer(values);
        } else {
          this.handleSubmit(values);
        }
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaPrice = askingPrice === YES ? `- ${formattedPrice}` : '';
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: schemaPrice, siteTitle }
    );

    const youtubeMedia = youtubeLinks[0] &&
      youtubeLinks.map((url) => ({
        thumbnail: getThumbnailFromYoutubeUrl(url),
        url: url.split("&")[0].replace("watch?v=", "embed/") // Eliminate unnecessary query params
      }));

    const media = youtubeMedia
      ? youtubeMedia.concat(currentListing.images)
      : currentListing.images;

    const onChangeCollections = searchParams => {
      history.push(
        createResourceLocatorString('ListingPage', routeConfiguration(), params, searchParams)
      );
    };

    const isTimedAuctionEnd = isTimedAuction && timedAuction?.endDate <= moment().valueOf();

    const isTradingDisabled = isOwnListing || isSold || isTimedAuctionEnd;
    const isBuyItNowAccepted = pricing.askingPrice === YES && (isTimedAuction ? isPendingAutomatedTimedAuction : true);
    const isOfferAccepted = pricing.acceptOffer === YES;
    const listingLink = `${config.canonicalRootURL}/l/${listingSlug}/${currentListing.id.uuid}`;

    return (
      <div>
        <Page
          title={schemaTitle}
          scrollingDisabled={scrollingDisabled}
          author={authorDisplayName}
          contentType="website"
          description={description}
          facebookImages={facebookImages}
          twitterImages={twitterImages}
          schema={{
            '@context': 'http://schema.org',
            '@type': 'ItemPage',
            description: description,
            name: schemaTitle,
            image: schemaImages,
          }}
        >
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar><TopbarContainer /></LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <div className={css.containerFluid}>
                <div className={css.mainFluid}>
                  <div className={css.twoColMain}>
                    <SectionMedia
                      title={title}
                      media={media}
                      indexMedia={this.state.indexMedia}
                      listing={currentListing}
                      isOwnListing={isOwnListing}
                      editParams={{
                        id: listingId.uuid,
                        slug: listingSlug,
                        type: listingType,
                        tab: listingTab,
                      }}
                      handleNavigateMediaClick={handleNavigateMediaClick}
                      mediaCarouselOpen={this.state.mediaCarouselOpen}
                      onMediaCarouselClose={() => this.setState({ mediaCarouselOpen: false })}
                      handleViewMediaClick={handleViewMediaClick}
                      onManageDisableScrolling={onManageDisableScrolling}
                    />
                    <SectionListMedia
                      title={title}
                      media={media}
                      listing={currentListing}
                      isOwnListing={isOwnListing}
                      editParams={{
                        id: listingId.uuid,
                        slug: listingSlug,
                        type: listingType,
                        tab: listingTab,
                      }}
                      handleMediaThumbnailClick={handleMediaThumbnailClick}
                    />
                  </div>
                  <div className={css.sidebar}>
                    <SectionHeading
                      category={category}
                      ensuredAuthor={ensuredAuthor}
                      title={title}
                      authorDisplayName={authorDisplayName}
                      showContactUser={showContactUser}
                      onContactUser={this.onContactUser}
                      isSold={isSold}
                      estimatePrice={estimatePrice}
                      editListingParams={{
                        id: listingId.uuid,
                        slug: listingSlug,
                        type: listingType,
                        tab: listingTab,
                      }}
                    />
                    {!isCollection && isTimedAuction && !isSold && <SectionTimedAuction timedAuction={timedAuction} intl={intl} />}
                    {!isCollection && <SectionTrading
                      className={css.tradingPanel}
                      price={price}
                      pricing={pricing}
                      highestOffer={highestOffer}
                      numberOfOffers={numberOfOffers}
                      onSubmit={handleBookingSubmit}
                      isTradingDisabled={isTradingDisabled}
                      isBuyItNowAccepted={isBuyItNowAccepted}
                      isOfferAccepted={isOfferAccepted}
                      intl={intl}
                      currentUser={currentUser}
                      currentHighestBidder={currentHighestBidder}
                    />}
                    <SectionWatchList
                      onClickToUpdateWatchlist={this.onClickToUpdateWatchlist}
                      numOfWatchers={numOfWatchers}
                      updateWatchlistInProgress={updateWatchlistInProgress}
                      updateWatchlistError={updateWatchlistError}
                      isWatched={isWatched}
                      isOwnListing={isOwnListing}
                    />
                    <div className={css.phoneContact}>
                      <IconPhone rootClassName={css.phoneContactIcon} />
                      <FormattedHTMLMessage id="ListingPage.phoneContact" />
                    </div>
                    {!isCollection && <SectionShippingOptions shipping={shipping} intl={intl} />}
                    <SectionShare listingLink={listingLink} title={title} intl={intl} />
                  </div>
                </div>
                <div className={css.main}>
                  {featuredRecords.length > 0 &&
                    <SectionFeaturedRecordsMaybe featuredRecords={featuredRecords} intl={intl} />
                  }
                  <SectionDescriptionMaybe description={displayDescription} />
                  {!isCollection && <SectionDetailsMaybe
                    category={category}
                    itemDetails={itemDetails}
                    micDetails={micDetails}
                    intl={intl}
                  />}
                  {authenticity.length > 0 && <SectionAuthenticityMaybe authenticity={authenticity} intl={intl} />}
                  {service && (service.technician || service.documents.length > 0) && <SectionServiceMaybe service={service} intl={intl} />}
                  {isCollection &&
                    <SectionCollectionMaybe
                      sectionRef={this.collectionSectionRef}
                      fetchCollectionsInProgress={fetchCollectionsInProgress}
                      fetchCollectionsError={fetchCollectionsError}
                      collections={collections}
                      pagination={pagination}
                      intl={intl}
                      searchParamsForPagination={parse(location.search)}
                      pagePathParams={rawParams}
                      onChangeCollections={onChangeCollections}
                    />
                  }
                  {showContactUser &&
                    <SectionMessageSeller
                      title={title}
                      authorDisplayName={authorDisplayName}
                      onContactUser={this.onContactUser}
                      isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                      onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                      sendEnquiryError={sendEnquiryError}
                      sendEnquiryInProgress={sendEnquiryInProgress}
                      onSubmitEnquiry={this.onSubmitEnquiry}
                      currentUser={currentUser}
                      onManageDisableScrolling={onManageDisableScrolling}
                    />
                  }
                </div>

                <Modal
                  id="ListingPage.enquiry"
                  contentClassName={css.enquiryModalContent}
                  isOpen={isAuthenticated && this.state.enquiryModalOpen}
                  onClose={() => this.setState({ enquiryModalOpen: false })}
                  usePortal
                  onManageDisableScrolling={onManageDisableScrolling}
                >
                  <EnquiryForm
                    className={css.enquiryForm}
                    submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                    listingTitle={title}
                    authorDisplayName={authorDisplayName}
                    sendEnquiryError={sendEnquiryError}
                    onSubmit={this.onSubmitEnquiry}
                    inProgress={sendEnquiryInProgress}
                  />
                </Modal>
                <Modal
                  id="ListingPage.verifyEmail"
                  containerClassName={css.modalContainer}
                  isOpen={isAuthenticated && this.state.emailVerificationReminderOpen}
                  onClose={() => this.setState({ emailVerificationReminderOpen: false })}
                  usePortal
                  onManageDisableScrolling={onManageDisableScrolling}
                >
                  <EmailReminder
                    user={currentUser}
                    onResendVerificationEmail={onResendVerificationEmail}
                    sendVerificationEmailInProgress={sendVerificationEmailInProgress}
                    sendVerificationEmailError={sendVerificationEmailError}
                  />
                </Modal>
              </div>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer className={css.footer} />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      </div>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
  updateWatchlistError: null
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  updateWatchlistInProgress: bool.isRequired,
  updateWatchlistError: propTypes.error
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    updateWatchlistInProgress,
    updateWatchlistError,
    currentPageResultIds,
    pagination,
    fetchCollectionsInProgress,
    fetchCollectionsError
  } = state.ListingPage;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const collections = getListingsById(state, currentPageResultIds);

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    updateWatchlistInProgress,
    updateWatchlistError,
    collections,
    pagination,
    fetchCollectionsInProgress,
    fetchCollectionsError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onCreatingOffer: value => dispatch(requestUpdateListing(null, value)),
  onUpdateWatchlist: (listingId, userId, action) => dispatch(updateCurrentWatchlist(listingId, userId, action)),
  dispatchOpenAuthModal: () => dispatch(openAuthModal()),
  onFetchCollections: (searchParams) => dispatch(fetchCollections(searchParams)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
